<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        class="overflow-x-auto"
        justify-center
        wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="'Bookings'"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Bookings'"
                    :user_id="user_id"
                    :user_type="user_type"
                    :pre-selected-filters="filters.selectedFilters"
                    :isDetail="true"
                    :isExport="true"
                    v-on:refreshData="getBookingRevenue"
        >
          <template slot="datatable-actions">
            <div class="action-buttons">
              <button class="btn btn-primary" @click="redirectBookingSession">{{ addBookingButtonText }}</button>
            </div>
          </template>
          <template slot="filters">
            <date-range-filter :filter-id="'booking_date_filter'"
                               :query-type="'Date'"
                               :column="'created_at'"
                               :operator="'between'"
                               :place-holder="'Filter by Booking Date'"
            >
            </date-range-filter>
            <!-- Filter for Session Date -->
            <date-range-filter :filter-id="'session_date_filter'"
                               :query-type="'relationship'"
                               :relation-ship-name="'therapistSlot'"
                               :column="'start_time'"
                               :operator="'relationShipBetween'"
                               :place-holder="'Filter by Session Date'"
                               class="mr-2"
            >
            </date-range-filter>
            <filters class="ml-2 mr-1" :filters="filters.paymentTypeFilters"></filters>

            <filters class="ml-2" :filters="filters.sessionTypes"></filters>
            <filters class="ml-2" :filters="filters.bookingStatus"></filters>
            <filters class="ml-2" :filters="filters.bookingTypeFilter"></filters>

            <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Source -->

            <multi-select-filters class="mr-2"
                                  :filter-id="'booking_utm_source_filter'"
                                  :filters="filters.bookingSourceFilter"
                                  :placeholder="'Select source'"
                                  :column="'utm_source'"
                                  :query-type="'relationship'"
                                  :operator="'in'"
                                  :relation-ship-name="'client.clientMarketingData'"
            ></multi-select-filters>

            <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Campaign-->

            <multi-select-filters :filter-id="'booking_utm_campaign_filters'"
                                  :filters="filters.bookingCampaignFilters"
                                  :placeholder="'Select campaign'"
                                  :column="'utm_campaign'"
                                  :query-type="'relationship'"
                                  :operator="'in'"
                                  :relation-ship-name="'client.clientMarketingData'"
            ></multi-select-filters>

            <!-- Filter those bookings which are booked by these organizations-->
            <template v-if="filters.orgFilters.length > 0">
              <multi-select-filters :filter-id="'orgFilters'"
                                    class="ml-2"
                                    :filters="filters.orgFilters"
                                    :placeholder="'Select organisation'"
                                    :column="'id'"
                                    :query-type="'relationship'"
                                    :relation-ship-name="'userVoucher.voucherCode.org'"
              ></multi-select-filters>
            </template>


            <template v-if="filters.planFilters.length > 0">
              <multi-select-filters :filter-id="'plan_filter'"
                                    class="ml-2"
                                    :filters="filters.planFilters"
                                    :placeholder="'Select Plan'"
                                    :column="'id'"
                                    :query-type="'relationship'"
                                    :relation-ship-name="'userVoucher.voucherCode.plan'"
              ></multi-select-filters>
            </template>

            <!-- Filter bookings by therapist-->

            <multi-select-filters
                                  v-if="filters.therapistFilter.length > 0"
                                  :filter-id="'therapist_filter_helper'"
                                  class="ml-2"
                                  :filters="filters.therapistFilter"
                                  :placeholder="'Select Therapist'"
                                  :column="'therapist_id'"
                                  :query-type="'relationship'"
                                  :operator="'in'"
                                  :relation-ship-name="'therapist'"
            ></multi-select-filters>

          </template>


          <template slot="stats" >
            <div class="display-flex flex-row m-t-20">

              <span class="mt-2 ml-2">
                 Revenue excl. therapy packages = £{{ revenueWithoutPackages }}
              </span>


              <span class="mt-2 ml-2">
                Revenue from therapy packages = {{ revenueOfPackages !== ignorePackagesRevenue ? "£" : "" }} {{ revenueOfPackages }}
              </span>

              <span class="mt-2 ml-2">
                Revenue total = £{{ totalRevenue }}
              </span>
            </div>
          </template>
        </data-table>
      </template>

      <!-- Add Booking -->
      <add-booking v-if="addBookingDialog"
                   :visible="addBookingDialog"
                   :action="this.action"
                   :booking="this.selectedBooking"
                   v-on:addBooking="addBooking"
                   v-on:reschedule="rescheduleSession"
                   v-on:hide="hideBookingModal"
      >
      </add-booking>

      <!-- Booking Detail -->
      <booking-detail v-if="bookingDetailDialog"
                      :visible="bookingDetailDialog"
                      :rowData="selectedBooking"
                      v-on:reschedule="showRescheduleModal"
                      v-on:openCallLogs="openCallLogs"
                      v-on:hide="hideDetailModal"
      >
      </booking-detail>

      <!-- Booking Call Logs -->
      <booking-call-logs v-if="callLogsDialog"
                         :visible="callLogsDialog"
                         :rowData="selectedBooking"
                         v-on:hide="closeCallLogs"
      >
      </booking-call-logs>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "@/components/common/dataTable/DataTable";
import AddBooking from "@/components/main/AddBooking";
import Filters from "@/components/common/dataTable/filters/Filters";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import BookingDetail from "@/components/main/BookingDetail";
import BookingCallLogs from '@/components/main/BookingCallLogs';
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import routeHelper from '../helpers/app/routeHelper'

export default {
  name: "Transactions",
  components: {
    DataTable,
    AddBooking,
    Filters,
    MultiSelectFilters,
    BookingDetail,
    BookingCallLogs,
    DateRangeFilter
  },
  props: {
    user_id: {
      user_id: [Number, String]
    },
    user_type: {
      type: String
    },
    header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiurl: 'bookings',
      search: {
        placeholder: 'Search by name, session type, status…',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'id',
          sortField: 'id',
          direction: 'desc'
        }
      ],
      filters: [],
      selected_filters: [],
      loaded: false,
      addBookingDialog: false,
      bookingDetailDialog: false,
      callLogsDialog: false,
      addBookingButtonText: 'Add Booking',
      selectedBooking: null,
      action: 'add',
      revenueWithoutPackages: 0,
      ignorePackagesRevenue: "NA",
      revenueOfPackages: 0
    }
  },

  computed: {

    totalRevenue: function () {
      let totalRevenue = parseFloat(this.revenueWithoutPackages) + parseInt(this.revenueOfPackages === this.ignorePackagesRevenue  ? 0 : this.revenueOfPackages);
      return totalRevenue.toFixed(2);
    }
  },

  events: {
    'open-details': function (data) {
      this.selectedBooking = data;
      this.bookingDetailDialog = true;
    },
  },
  destroyed() {
    this.$events.off('get-revenue');
  },
  created() {
    this.fetchFilters();
    let bookingId = this.$route.query.booking_id;
    if (bookingId) {
      this.search.data = bookingId;
    }

  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/bookings').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
    hideDetailModal() {
      this.action = 'add';
      this.selectedBooking = null;
      this.bookingDetailDialog = false;
    },
    redirectBookingSession() {
      window.location.href = "/add_booking";
    },
    showBookingModal() {
      this.addBookingDialog = true;
    },
    hideBookingModal() {
      this.addBookingDialog = false;
    },
    showRescheduleModal(session) {
      this.selectedBooking = session;
      this.action = 'reschedule';
      this.showBookingModal();
    },
    openCallLogs() {
      this.bookingDetailDialog = false
      this.callLogsDialog = true
    },
    closeCallLogs() {
      this.callLogsDialog = false
      this.bookingDetailDialog = true
    },
    addBooking(booking_data) {
      let loader = this.$loading.show();
      this.$http.put(this.url + 'booking', booking_data).then(response => {
        loader.hide();
        this.hideBookingModal();
        this.$events.fire('vuetable:reload');
        this.$toastr('success', response.data.message, '');
        this.trackBooking(response.data.booking_id);
      }).catch(error => {
        loader.hide();
        this.$toastr('add', {
          msg: error.response.data.message, // Message
          timeout: 13000, // Timeout in ms
          type: 'error',
        });
      });
    },
    trackBooking(bookedSessionID) {
      this.$http.get(this.url + 'track_booking_event', {
        params: {
          booking_id: bookedSessionID
        }
      }).then(response => {
        let bookingData = response.data.data;

        // E-commerce event
        window.dataLayer = window.dataLayer || [];

        let products = [{
          'name': bookingData.product_name,
          'id': bookingData.product_sku,
          'price': bookingData.revenue,
          'brand': bookingData.affiliation,
          'category': bookingData.product_category,
          'variant': bookingData.product_variant,
          'quantity': 1,
          'coupon': ''
        }];

        window.dataLayer.push({
          'event': 'eec_purchase',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': bookingData.transaction_id.toString(),                         // Transaction ID. Required for purchases and refunds.
                'affiliation': bookingData.affiliation,
                'revenue': bookingData.revenue,                     // Total transaction value (incl. tax and shipping)
                'tax': 0,
                'shipping': 0,
                'coupon': '',
                'products': products
              },
            }
          }
        });
      }).catch(error => {
        console.log(error);
      });
    },
    rescheduleSession(booking_data) {
      let loader = this.$loading.show();
      this.$http.put(this.url + routeHelper.rescheduleSessionApi, {
        booking_id: booking_data.id,
        rescheduled_slot_id: booking_data.therapist_slot_id,
        action: 'request',
        timezone: this.timezone,
        device_type: this.deviceType
      }).then(response => {
        loader.hide();
        this.hideBookingModal();
        this.hideDetailModal();
        this.$events.fire('vuetable:reload');
        this.$toastr('success', response.data.message, '');
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });
    },
    getBookingRevenue(query) {
      this.getBookingRevenueWithoutPackages(query);
      this.getBookingRevenueOfPackages(query);
    },

    getBookingRevenueWithoutPackages(query) {

      let originalURL = this.url + 'booking-revenue-without-packages?' + query;
      let alteredURL = this.removeSortFromUrl("sort", originalURL);
      this.$http.get(alteredURL).then(response => {
        this.revenueWithoutPackages = response.data;
      }).catch(error => {
        console.log(error);
      });

    },

    getBookingRevenueOfPackages(query) {

      let originalURL = this.url + 'booking-revenue-of-packages?' + query;
      let alteredURL = this.removeSortFromUrl("sort", originalURL);
      this.$http.get(alteredURL).then(response => {
        this.revenueOfPackages = response.data;
      }).catch(error => {
        console.log(error);
      });

    },


    removeSortFromUrl(key, sourceURL) {
      let rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    }
  }
}
</script>
<style scoped>

</style>
